/**
 * JSOcean Quantity Selector: Mobile Version
 */
import { QuantitySelectorSettings, IQuantitySelectorField } from '../../interfaces/quantity-selector';
import {render, handleChangeQtyEvent} from './quantity-selector-helper';

class JSOQuantitySelectorMobile{

    /**
     * properties
     */
    settings: QuantitySelectorSettings;
    onFieldsUpdate: Function;
    fields: IQuantitySelectorField[];
    $root: HTMLDivElement;
    $eventsRoot: HTMLElement;

    /**
     * init
     * @param {object=} settings
     * @param {Array.<{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}>} fields
     * @param {Function} onFieldsUpdate
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(settings: QuantitySelectorSettings, fields: IQuantitySelectorField[], onFieldsUpdate: Function, $eventsRoot: HTMLElement) {

        this.settings = settings;
        this.onFieldsUpdate = onFieldsUpdate;

        // deep copy of fields array
        this.fields = JSON.parse(JSON.stringify(fields));

        this.$root = document.createElement('div');
        this.$root.className = `jso-popup jso-popup-fullscreen ${this.settings.popupClassName}`;

        this.$eventsRoot = $eventsRoot || this.$root;

        // update dropdown view
        this.updateView();
    }

    // ---------- RENDER --------------

    /**
     * update dropdown view
     */
    updateView(){

        // render date picker dropdown
        this.$root.innerHTML = this.render();

        // handle popup events
        this.handlePopupEvents();
    }

    /**
     * render
     */
    render(){

        return `
            <div class="jso-popup-box">
                <div class="jso-popup-content-top">
                    <h4>${this.settings.mobileHeaderText || '&nbsp;'}</h4>
                    <button type="button" class="jso-popup-close-button ${this.settings.popupCloseButtonClass}">&nbsp;</button>
                </div>    
                <div class="jso-quantity-selector-popup-content">
                    ${render(this.fields)}
                </div>
                <div class="jso-quantity-selector-popup-footer">
                    <button type="button" class="jso-quantity-selector-popup-done-btn">${this.settings.doneButtonText}</button>
                </div>
            </div>
        `;
    }

    // ---------- EVENTS --------------

    /**
     * handle popup events
     */
    handlePopupEvents(){

        /**
         * prevent close / open when click on dropdown content
         */
        const $content = this.$root.querySelector('.jso-popup-box');

        if($content){
            $content.addEventListener('click', evt => {

                const $target = evt.target as HTMLFormElement;

                if($target && $target.classList.contains('jso-quantity-selector-field-btn')){

                    // handle qty button click event
                    this.onQtyChange($target, 'button');
                }

                evt.stopPropagation();
            });

            $content.addEventListener('change', evt => {

                const $target = evt.target as HTMLFormElement;

                if($target && $target.classList.contains('jso-quantity-selector-select')){

                    this.onQtyChange($target, 'select');
                }
            });
        }

        /**
         * handle close button click
         */
        const $close = this.$root.querySelector('.jso-popup-close-button');

        if($close){
            $close.addEventListener('click', evt => {
                this.close();
            });
        }

        /**
         * handle done button click
         */
        const $done = this.$root.querySelector('.jso-quantity-selector-popup-done-btn');

        if($done){
            $done.addEventListener('click', evt => {
                this.close();
            });
        }
    }

    /**
     * on qty change
     * @param {HTMLElement} $control
     * @param {string} controlType - 'button' or 'select'
     */
    onQtyChange($control: HTMLFormElement, controlType: string){

        // deep copy of fields array
        const fields = JSON.parse(JSON.stringify(this.fields));

        if(handleChangeQtyEvent($control, fields, controlType)){

            this.fields = fields;

            // update dropdown view
            this.updateView();
        }
    }

    // ---------- HELPERS -------------

    /**
     * handle popup close
     */
    close(){
        // close and update fields on the parent
        if(this.onFieldsUpdate) {
            this.onFieldsUpdate(this.fields);
        }
    }
}

export default JSOQuantitySelectorMobile;