import {log, deepMerge, dispatchEvent} from './domain/common';
import { Settings } from './interfaces/interfaces';
import JSOAutocomplete from './domain/autocomplete/autocomplete';
import JSORangeDatePicker from './domain/date-range-picker/date-range-picker';
import JSOQuantitySelector from './domain/quantity-selector/quantity-selector';
import { addDays } from 'date-fns';
import { twoDigits } from './domain/time';
import { addQueryString } from './domain/url';
import { getJSON } from './dal/fetch';
import {textFilter} from './domain/text-filters';
import {removeCharacters} from './domain/text';
import { IQuantitySelectorField } from './interfaces/quantity-selector';

// today is used to provide default parameters to the date range picker
const temp = new Date();
const today = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate(), 0, 0, 0);
const tomorrow = addDays(today, 1);

/**
 * plugin defaults
 * @type {Object}
 */
const defaults: Settings = {
   rootClassName: 'jso-booking-form',
   errorTooltipClassName: 'jso-booking-form-error-msg',
   mobileBreakpoint: 768, // if viewport width <= mobileBreakpoint -> opened mobile popup instead of desktop dropdown
   redirectAfterSubmit: true, // if true, the plugin will redirect to the link in the actions attribute of the form element after form submit; otherwise, it will stop.

   // animation settings
   animation: '', // possible values: '', 'fade', 'slide'
   duration: 300, // in milliseconds

   // storage settings
   storageType: '', // '', 'local-storage', 'session-storage', 'cookies'
   storageName: 'jso-booking-form', // this setting is used like key name in web storage, or like cookie name
   cookiesExpiration: -1, // cookies expiration in minutes (-1 = cookies expire when browser is closed)

   // countries autocomplete settings
   countriesAutocomplete: {

      // class names
      countriesClassName: 'jso-booking-form-country',
      inputClassName: 'jso-booking-form-country-input',

      // data and filter
      dataURL: 'countries.json',
      flagsBaseURL: 'flags/32/',
      maxResults: 5,
      ignoreRegex: '[~!@#$%^&*()+=`\'"\/\\_]+',
      noResultsText: 'No results found',
      filterMode: 'contains', // 'startsWith', 'contains', 'equals'
      enableHighlighting: true,

      // mobile version settings and classes
      mobileHeaderText: 'Destination',
      mobilePlaceholderText: 'Select a country',

      // validation
      validationError: 'Destination is required.',

      // custom data source settings
      customDataSource: {

         // enabled / disabled
         enabled: false,

         // this function will execute each time user changes country name
         onSelect: (userInput: string) => {},

         // this function is used to render a country received from the custom data source
         renderCountry: (countryData: any, inputValue: string, index: number) => '',

         // this function is used to filter countries by user input in the case when the user didn't use the drop-down
         filterCountry: (countries: any, userInput: string) => {},

         // this function maps custom country properties to the data that will be passed as GET / POST request after the form submission
         getSubmitProperties: (countryData: any, inputValue: string) => {}
      },

      // used to prefix all events triggered by autocomplete, for example 'autocomplete-loaded' etc.
      eventsPrefix: 'jso-countries-autocomplete-',

      // nested object property name
      storageProperty: 'countriesAutocomplete'
   },

   // check in / out settings
   checkInOut: {

      // predefined dates
      from: today, // date or null
      to: tomorrow, // date or null

      // class names
      checkInOutClassName: 'jso-booking-check-in-out',

      // nights count
      nightsCountEnabled: true,
      nightsCountText: 'night stay',

      // the number of months that will be rendered after the current month before the next scroll
      mobileMonthsStep: 5,
      mobileHeaderText: 'Dates',

      // validation
      validationError: 'Both dates are required.',

      // texts
      doneButtonText: 'Done', // this text appears on the 'Done' button on desktop and mobile.
      dayNames2: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      dayNames3: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNames3: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      weekStart: 0, // 0 for Sunday - 6 for Saturday
   },

   // guests selector
   guestsSelector: {

      // class names
      guestsClassName: 'jso-booking-form-guests',

      // mobile
      doneButtonText: 'Done',
      mobileHeaderText: 'Travelers',

      // general settings
      fields: {

         adults: {
            titleSingular: 'Adult',
            titlePlural: 'Adults',
            valueTextSingular: '',
            valueTextPlural: '',
            quantity: 2,
            step: 1,
            min: 1,
            max: 30, // undefined = unlimited number of adults
            type: 'buttons' // buttons, select
         },

         children: {
            titleSingular: 'Children',
            titlePlural: 'Children',
            valueTextSingular: '',
            valueTextPlural: '',
            quantity: 0,
            step: 1,
            min: 0,
            max: 10, // undefined = unlimited number of children
            type: 'buttons' // buttons, select
         },

         age: {
            titleSingular: 'How old is the child?',
            titlePlural: 'How old are the children?',
            valueTextSingular: ' year old',
            valueTextPlural: ' years old',
            quantity: 12,
            step: 1,
            min: 0,
            max: 17,
            parentField: 'children',
            type: 'select' // buttons, select
         },

         rooms: {
            titleSingular: 'Room',
            titlePlural: 'Rooms',
            valueTextSingular: '',
            valueTextPlural: '',
            codeName: 'rooms',
            quantity: 1,
            step: 1,
            min: 1,
            max: 30, // undefined = unlimited number of rooms
            type: 'buttons' // buttons, select
         }
      }
   },

   // print errors in console
   debug: false
};

/**
 * Ocean Star - Hotels Search Form
 */
class JSOBookingForm{

   /**
    * properties
    */
   settings: Settings;
   $root: HTMLElement;
   $autocompleteRoot: HTMLElement;
   $dateRangePickerRoot: HTMLElement;
   $quantitySelectorRoot: HTMLElement;
   dateRangePicker: JSORangeDatePicker;
   quantitySelector: JSOQuantitySelector;
   autocomplete: JSOAutocomplete;

   /**
    * init
    */
   constructor(userSettings: Settings, $root: HTMLElement) {
      this.settings = userSettings ? deepMerge(defaults, userSettings) : {...defaults};
      this.$root = $root || document.querySelector(`.${this.settings.rootClassName}`);

      if(!this.$root){
         log(this.settings, `An element with class "${this.settings.rootClassName}" is missing in HTML.`);
         // it's not possible to continue
         return;
      }

      // init countries autocomplete -----------------------
      this.$autocompleteRoot = this.$root.querySelector(`.${this.settings.countriesAutocomplete.countriesClassName}`);

      if(!this.$autocompleteRoot){
         log(this.settings, `An element with class "${this.settings.countriesAutocomplete.countriesClassName}" is missing in HTML.`);
      }
      else{
         this.initAutocomplete();
      }

      // init date range picker ----------------------------
      this.$dateRangePickerRoot = this.$root.querySelector(`.${this.settings.checkInOut.checkInOutClassName}`);

      if(!this.$dateRangePickerRoot){
         log(this.settings, `An element with class "${this.settings.checkInOut.checkInOutClassName}" is missing in HTML.`);
      }
      else{
         this.dateRangePicker = new JSORangeDatePicker(this.passGeneralSettings(this.settings.checkInOut), this.$dateRangePickerRoot, this.$root);
      }

      // init quantity selector ----------------------------
      this.$quantitySelectorRoot = this.$root.querySelector(`.${this.settings.guestsSelector.guestsClassName}`);

      if(!this.$quantitySelectorRoot){
         log(this.settings, `An element with class "${this.settings.guestsSelector.guestsClassName}" is missing in HTML.`);
      }
      else{
         const qtySelectorSettings = this.passGeneralSettings(this.settings.guestsSelector);
         qtySelectorSettings.fields = this.reformatGuestFields();
         this.quantitySelector = new JSOQuantitySelector(qtySelectorSettings, this.$quantitySelectorRoot, this.$root);
      }

      // handle general events
      this.handleEvents();
   }

   // ------------------ AUTOCOMPLETE DATA -------------------

   /**
    * init autocomplete control
    */
   async initAutocomplete(){

      // init autocomplete settings
      const autocompleteSettings = this.passGeneralSettings(this.settings.countriesAutocomplete);

      // check if custom data source enabled
      const customDataSourceEnabled = autocompleteSettings.customDataSource && autocompleteSettings.customDataSource.enabled;

      // countries data from 'data/countries.json'
      let data: any = [];

      if(!customDataSourceEnabled){

         /**
          * on autocomplete value change
          * @param {string} userInput
          * @return {Promise.<Array.<object>>} - filtered items
          */
         autocompleteSettings.onChange = (userInput: string) => {
            return new Promise((resolve, reject) => {

               const filtered = textFilter(data, userInput, autocompleteSettings.filterMode, 'country', autocompleteSettings.maxResults, autocompleteSettings.ignoreRegex)
               resolve(filtered);
            });
         };

         /**
          * render autocomplete item (country)
          * @param {object} dataItem
          * @param {string} inputValue
          * @param {number} index - data item index in the list
          * @return {string} html
          */
         autocompleteSettings.renderItem = (dataItem: any, inputValue: string, index: number) => {

            const formattedInput = removeCharacters(inputValue, autocompleteSettings.ignoreRegex);
            const regex = new RegExp(`(${formattedInput})`, 'gi');

            let itemValue = dataItem.country;

            if(autocompleteSettings.enableHighlighting) {
               itemValue = dataItem.country.replace(regex, '<span class="jso-highlight-text">$1</span>');
            }

            return `
             <div class="jso-autocomplete-item" data-index="${index}" data-code2="${dataItem.code2}" data-code3="${dataItem.code3}" tabindex="0">
                 <img src="${autocompleteSettings.flagsBaseURL}${dataItem.code2}.png" alt=""/>
                 <span>${itemValue}</span>
             </div>
         `;
         };

         /**
          * this function is used to filter countries by user input in the case when the user didn't use the drop-down
          * but just insert the value manually and press ENTER
          * @param {Array.<object>} data
          * @param {string} userInput
          * @return {object|null} selected country or null
          */
         autocompleteSettings.filter = (data: any, userInput: string) => {
            const filtered = textFilter(data, userInput, 'equals', 'country', autocompleteSettings.maxResults, autocompleteSettings.ignoreRegex);
            return filtered && filtered.length > 0 ? filtered[0] : null;
         };

         /**
          * this function maps custom country properties to the data that will be passed as GET / POST request after the form submission
          * @param {object} dataItem
          * @param {string} inputValue
          * @return {Array.<object>|null}
          */
         autocompleteSettings.getSubmitProperties = (dataItem: any, inputValue: string) => {

            // in case of a value predefined in text box, or restored from storage
            if(!dataItem){

               return inputValue.trim() ? [
                  {name: 'country_name', value: inputValue.trim() },
                  {name: 'country_code2', value: ''},
                  {name: 'country_code3', value: ''},
               ] : null;
            }

            return [
               { name: 'country_name', value: dataItem ? dataItem.country : ''},
               { name: 'country_code2', value: dataItem ? dataItem.code2 : ''},
               { name: 'country_code3', value: dataItem ? dataItem.code3 : ''},
            ];
         }
      }
      else{
         autocompleteSettings.onChange = autocompleteSettings.customDataSource.onSelect;
         autocompleteSettings.renderItem = autocompleteSettings.customDataSource.renderCountry;
         autocompleteSettings.filter = autocompleteSettings.customDataSource.filterCountry;
         autocompleteSettings.getSubmitProperties = autocompleteSettings.customDataSource.getSubmitProperties;
      }

      // create autocomplete instance
      this.autocomplete = new JSOAutocomplete(autocompleteSettings, this.$autocompleteRoot, this.$root);

      if(!customDataSourceEnabled) {

         // load autocomplete data from data.countries file
         data = await getJSON(autocompleteSettings.dataURL, autocompleteSettings.debug) || [];

         // dispatch event that countries data is loaded via AJAX request
         dispatchEvent(this.$root, `${autocompleteSettings.eventsPrefix}loaded`, data);
      }

   }

   // ------------------ SETTINGS -----------------------

   /**
    * some settings are common to all the inner widgets and they should be passed through
    * @param {object} widgetSettings
    * @return {object}
    */
   passGeneralSettings(widgetSettings: object){

      return deepMerge(widgetSettings, {
         debug: this.settings.debug,
         mobileBreakpoint: this.settings.mobileBreakpoint,
         animation: this.settings.animation || '',
         duration: this.settings.duration || 300,
         storageType: this.settings.storageType || '',
         storageName: this.settings.storageName || 'jso-booking-form',
         cookiesExpiration: this.settings.cookiesExpiration || -1,
      });
   }

   /**
    * guests fields defined here are not in the format that required by qty selector widget,
    * and they should be reformatted.
    * It works this way so to the user will be easier overwrite some values
    * @return {Array.<object>}
    */
   reformatGuestFields(){

      const source = this.settings.guestsSelector.fields;
      const target = [];
      const dependentFields = [];

      // add all main fields to array
      for (let [fieldCodeName, fieldData] of Object.entries(source)) {

         if(fieldCodeName && !fieldData) continue;

         const field = {...(fieldData as IQuantitySelectorField[])} as IQuantitySelectorField; // deepMerge is not needed here for now
         field.codeName = fieldCodeName;

         if(field.parentField){

            // this field depends on other field
            dependentFields.push(field);
         }
         else{
            target.push(field);
         }
      }

      // move dependant fields to the proper places
      for(let i=0; i<dependentFields.length; i++){

         const dependantField = dependentFields[i];
         const parentField = target.find(item => item.codeName === dependantField.parentField);

         if(parentField){
            parentField.dependantField = dependantField;
            parentField.dependantField.parentField = undefined; // clear helper property
         }
      }

      return target;
   }

   // ------------------ EVENTS -----------------------

   /**
    * handle general events
    */
   handleEvents(){

      /**
       * once autocomplete drop-down or mobile popup opens -> clear validation errors
       */
      this.$root.addEventListener(`${this.settings.countriesAutocomplete.eventsPrefix}opened`, () => {

         // remove previous error classes
         this.removeValidationErrors();
      });

      /**
       * user presses ENTER when no value is entered -> should cause validation error
       */
      this.$root.addEventListener(`${this.settings.countriesAutocomplete.eventsPrefix}invalid`, () => {
         this.handleFormSubmit();
      });

      /**
       * on autocomplete click -> close other dropdowns and clear error classes
       */
      if(this.$autocompleteRoot) {
         this.$autocompleteRoot.addEventListener('click', () => {

            // remove previous error classes
            this.removeValidationErrors();

            // close date range picker
            if (this.dateRangePicker) {
               this.dateRangePicker.close();
            }

            // close quantity selector
            if (this.quantitySelector) {
               this.quantitySelector.close();
            }
         });
      }

      /**
       * on date range picker click -> close other dropdowns and clear error classes
       */
      if(this.$dateRangePickerRoot) {
         this.$dateRangePickerRoot.addEventListener('click', () => {

            // remove previous error classes
            this.removeValidationErrors();

            // close quantity selector
            if (this.quantitySelector) {
               this.quantitySelector.close();
            }

            // close autocomplete
            if (this.autocomplete) {
               this.autocomplete.close();
            }
         });

      }

      /**
       * on quantity selector click -> close other dropdowns and clear error classes
       */
      if(this.$quantitySelectorRoot) {
         this.$quantitySelectorRoot.addEventListener('click', () => {

            // remove previous error classes
            this.removeValidationErrors();

            // close date range picker
            if (this.dateRangePicker) {
               this.dateRangePicker.close();
            }

            // close autocomplete
            if (this.autocomplete) {
               this.autocomplete.close();
            }
         });
      }

      /**
       * on form submit -> validate and proceed
       */
      this.$root.addEventListener('submit', evt => {

         // stop submitting the form
         evt.preventDefault();

         // handle form submit event
         this.handleFormSubmit();
      });
   }

   // ------------------ FORM SUBMISSION ---------------

   /**
    * handle form submit
    */
   handleFormSubmit(){

      // remove previous error classes
      this.removeValidationErrors();

      // validate the user input
      const isValidRes = this.isValid();

      // add error messages if needed
      if(!isValidRes.isValid){

         const len = isValidRes.widgets.length;

         // add general error
         this.$root.classList.add(`${this.settings.rootClassName}-error`);

         for(let i=0; i<len; i++){

            const item = isValidRes.widgets[i];
            this.$root.classList.add(item.className);

            if(item.widget && typeof item.widget.showTooltip === 'function'){
               item.widget.showTooltip(item.errorMessage, this.settings.errorTooltipClassName);
            }
         }
      }

      // dispatch validation event
      dispatchEvent(this.$root, 'jso-booking-form-validated', {
         isValid: isValidRes.isValid,
         errors: isValidRes.widgets ? isValidRes.widgets.map(widget => {
            return {
               className: widget.className,
               errorMessage: widget.errorMessage
            }
         }) : []
      });

      if(!isValidRes.isValid) {
         // stop here
         return;
      }

      // submit the form after validation has passed
      this.submit();
   }

   /**
    * get values of all the widgets
    * @return {Array.<{name: string, value: *}>}
    */
   getValue(){

      const quantity = this.quantitySelector.getValue();

      const isFromDefined = this.dateRangePicker && this.dateRangePicker.selectedRange && this.dateRangePicker.selectedRange.from;
      const isToDefined = this.dateRangePicker && this.dateRangePicker.selectedRange && this.dateRangePicker.selectedRange.to;

      const countryData = (this.autocomplete ? this.autocomplete.getValue() : []) || [];

      // if all is well -> submit the form
      const data = [

         // country
         ...countryData,

         // dates
         { name: 'from_date', value: isFromDefined ? this.formatDate(this.dateRangePicker.selectedRange.from) : null},
         { name: 'to_date', value: isToDefined ? this.formatDate(this.dateRangePicker.selectedRange.to) : null},
      ];

      // handle quantity
      for(let i=0; i<quantity.length; i++){
         const qtyItem = quantity[i];
         data.push({ name: qtyItem.codeName, value: qtyItem.quantity });
      }

      return data;
   }

   /**
    * helper: submit the form after validation has passed
    */
   submit(){

      const data = this.getValue();

      // generate querystring
      const qs = data.map(item => `${encodeURIComponent(item.name)}=${encodeURIComponent(item.value)}`).join('&');

      // dispatch submit event
      dispatchEvent(this.$root, 'jso-booking-form-submit', {
         data,
         queryString: qs
      });

      if(this.settings.redirectAfterSubmit) {

         const action = this.$root.getAttribute('action');
         window.location.href = addQueryString(action, qs);
      }
   }

   /**
    * format date for query string
    * @param {Date} date
    * @return {string} yyyy-mm-dd
    */
   formatDate(date: Date){
      const month = twoDigits(date.getMonth() + 1);
      const day = twoDigits(date.getDate());
      return `${date.getFullYear()}-${month}-${day}`;
      // return format(date, 'yyyy-MM-dd');
   }

   // ------------------ VALIDATION -------------------

   /**
    * check if the form is valid
    * @return {{isValid: boolean, className: Array.<object> }}
    */
   isValid() {

      if (!this.autocomplete || !this.dateRangePicker || !this.quantitySelector){
         return {
            isValid: false,
            widgets: []
         };
      }

      const result = {
         isValid: true,
         widgets: [] as any[]
      }

      // validate autocomplete
      const autocompleteValue = this.autocomplete.getValue();

      if(!autocompleteValue){
         result.isValid = false;
         result.widgets.push({
            className: `${this.settings.countriesAutocomplete.countriesClassName}-error`,
            widget: this.autocomplete,
            errorMessage: this.settings.countriesAutocomplete.validationError
         });
      }

      // date range picker should have [from, to] range
      if(!this.dateRangePicker.selectedRange || !this.dateRangePicker.selectedRange.from || !this.dateRangePicker.selectedRange.to){
         result.isValid = false;
         result.widgets.push({
            className: `${this.settings.checkInOut.checkInOutClassName}-error`,
            widget: this.dateRangePicker,
            errorMessage: this.settings.checkInOut.validationError
         });
      }

      // validate quantity selector
      const fields = this.quantitySelector.getValue();
      if(!fields || fields.length <= 0){
         result.isValid = false;
         result.widgets.push({
            className: `${this.settings.guestsSelector.guestsClassName}-error`,
            widget: this.quantitySelector,
            errorMessage: this.settings.guestsSelector.validationError
         });
      }

      return result;
   }

   /**
    * remove validation errors
    */
   removeValidationErrors(){

      this.$root.classList.remove(`${this.settings.countriesAutocomplete.countriesClassName}-error`);
      this.$root.classList.remove(`${this.settings.checkInOut.checkInOutClassName}-error`);
      this.$root.classList.remove(`${this.settings.guestsSelector.guestsClassName}-error`);

      // remove general error
      this.$root.classList.remove(`${this.settings.rootClassName}-error`);

      // remove error message tooltip from autocomplete
      if(this.autocomplete && typeof this.autocomplete.hideTooltip === 'function'){
         this.autocomplete.hideTooltip();
      }

      // remove error message tooltip from date range picker
      if(this.dateRangePicker && typeof this.dateRangePicker.hideTooltip === 'function'){
         this.dateRangePicker.hideTooltip();
      }

      // remove error message tooltip from qty selector
      if(this.quantitySelector && typeof this.quantitySelector.hideTooltip === 'function'){
         this.quantitySelector.hideTooltip();
      }
   }
}

export default JSOBookingForm;