import { IQuantitySelectorField } from '../../interfaces/quantity-selector';

/**
 * JSOcean Quantity Selector Helper
 */

/**
 * render
 */
export const render = (fields: IQuantitySelectorField[], parentField: IQuantitySelectorField = null): string => {

    return fields.map((field, index) => {

        const parent = parentField ? `data-parent="${parentField.codeName}" data-index="${field.index}"` : '';

        return `
            <div class="jso-quantity-selector-field ${parentField ? 'jso-quantity-selector-dependant-field' : ''}" data-code="${field.codeName}" ${parent}>
                ${parentField ? '' : `<div class="jso-quantity-selector-title">${field.titlePlural}</div>`}
                <div class="jso-quantity-selector-qty-chooser">
                    ${field.type === 'select' ? renderSelectType(field, parent) : renderButtonsType(field, parent)}
                </div>
            </div>
            
            ${field.dependantField && field.fields && field.fields.length > 0 ? `
                <div class="jso-quantity-selector-dependant-fields">
                    <div class="jso-quantity-selector-dependant-field-title">${field.fields.length === 1 ? field.dependantField.titleSingular : field.dependantField.titlePlural}</div>
                    <div class="jso-quantity-selector-dependant-fields-box">
                        ${render(field.fields, field)}
                    </div>
                </div>
            ` : ''}
        `;
    }).join('');
};

/**
 * render type 'buttons'
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}} field
 * @param {string} parent - data-parent="code name"
 */
const renderButtonsType = (field: IQuantitySelectorField, parent: string) => {

    const minusDisabled = ifCanSubtract(field) ? '' : 'disabled';
    const plusDisabled = ifCanAdd(field) ? '' : 'disabled';

    let text = field.quantity + '';

    if(field.quantity === 1 && field.valueTextSingular){
        text += field.valueTextSingular;
    }

    if(field.quantity !== 1 && field.valueTextPlural){
        text += field.valueTextPlural;
    }

    return `
        <button 
            type="button" 
            class="jso-quantity-selector-field-btn jso-quantity-selector-field-btn-minus" 
            ${minusDisabled}
            data-type="minus"
            data-code="${field.codeName}" ${parent}>-</button>
            
        <div 
            class="jso-quantity-selector-field-qty"
            data-code="${field.codeName}">${text}</div>
        
        <button 
            type="button" 
            class="jso-quantity-selector-field-btn jso-quantity-selector-field-btn-plus"
            ${plusDisabled}
            data-type="plus"
            data-code="${field.codeName}" ${parent}>+</button>
    `;
};

/**
 * render type 'select'
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}} field
 * @param {string} parent - data-parent="code name"
 */
const renderSelectType = (field: IQuantitySelectorField, parent: string) => {

    const nums = [];

    for(let i = field.min; i <= field.max; i += field.step) {
        nums.push(i);
    }

    return `
        <select class="jso-quantity-selector-select" data-code="${field.codeName}" ${parent}>
            ${nums.map(num => {
                
                let text = num + '';
                
                if(num === 1 && field.valueTextSingular){
                    text += field.valueTextSingular;
                }

                if(num !== 1 && field.valueTextPlural){
                    text += field.valueTextPlural;
                }
               
                return `<option value="${num}" ${(num === field.quantity ? 'selected' : '')}>${text}</option>`;
            })}.join('');
        </select>
    `;
};

/**
 * on qty change
 * @param {HTMLElement} $control
 * @param {Array.<{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}>} fields
 * @return {boolean} - true if should be changed
 * @param {string} controlType - 'button' or 'select'
 */
export const handleChangeQtyEvent = ($control: HTMLFormElement, fields: IQuantitySelectorField[], controlType: string) => {

    const codeName = $control.getAttribute('data-code');
    if(!codeName) return false;

    // update field quantity
    const type = $control.getAttribute('data-type');

    // type should be only + / -
    if(controlType === 'button' && type !== 'minus' && type !== 'plus') return false;

    // check if this field is dependant
    const parentCodeName = $control.getAttribute('data-parent');

    if(parentCodeName){  // dependant field

        // find field by parent code name
        const field = fields.find(f => f.codeName === parentCodeName);
        if(!field) return false;

        // find nested field in sub array
        const index = Number($control.getAttribute('data-index')) || 0;
        const nestedField = field.fields.find(f => f.codeName === codeName && f.index === index);

        if(!nestedField) return false;

        // update field qty
        updateFieldQuantity($control, nestedField, type, controlType);
    }
    else{ // regular field

        // find field by code name
        const field = fields.find(f => f.codeName === codeName);
        if(!field) return false;

        // update field qty
        updateFieldQuantity($control, field, type, controlType);

        // handle dependant fields if needed
        if(field.dependantField){

            handleDependantField($control, field, type, controlType);
        }
    }

    return true;
};

/**
 * true if can subtract
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}} field
 * @return {boolean}
 */
export const ifCanSubtract = (field: IQuantitySelectorField) => {
    return field.quantity - field.step >= field.min;
};

/**
 * true if can add
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}} field
 * @return {boolean}
 */
export const ifCanAdd = (field: IQuantitySelectorField) => {
    return field.max === undefined || field.quantity + field.step <= field.max;
};

/**
 * update field quantity
 * @param {HTMLElement} $control
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}} field
 * @param {string} type - plus / minus
 * @param {string} controlType - 'button' or 'select'
 */
export const updateFieldQuantity = ($control: HTMLFormElement, field: IQuantitySelectorField, type: string, controlType: string) => {

    if(controlType === 'button'){

        if(type === 'minus' && ifCanSubtract(field)){
            field.quantity -= field.step;
        }

        if(type === 'plus' && ifCanAdd(field)){
            field.quantity += field.step;
        }
    }

    if(controlType === 'select'){
        const newValue = Number($control.value);

        if(!isNaN(newValue)){
            field.quantity = newValue;
        }
    }
};

/**
 * handle dependant field
 * @param {HTMLElement} $control
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined, dependantField: Array|undefined}} field
 * @param {string} type - plus / minus
 * @param {string} controlType - 'button' or 'select'
 */
export const handleDependantField = ($control: HTMLFormElement, field: IQuantitySelectorField, type: string, controlType: string) => {

    // ensure fields sub array
    field.fields = field.fields || [];

    if(controlType === 'select'){

        const fieldsNum = Number($control.value);

        if(isNaN(fieldsNum)) return;

        if(fieldsNum < field.fields.length){

            // remove last N fields
            removeFields(field, field.fields.length - fieldsNum);
        }

        if(fieldsNum > field.fields.length){

            // add new fields
            addFields(field, fieldsNum - field.fields.length);
        }
    }

    // TODO: handle possible maximum
    if(controlType === 'button' && type === 'plus'){

        addFields(field, 1);
    }

    if(controlType === 'button' && type === 'minus' && field.fields.length > 0){

        // remove last item
        removeFields(field, 1);
    }
};

/**
 * add N fields
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined}} field
 * @param {Number} N
 */
const addFields = (field: IQuantitySelectorField, N: number) => {

    for(let i=0; i<N; i++){

        // make a copy of dependant field
        const copy = {...field.dependantField};
        copy.index = field.fields.length;

        // add it to the sub array of fields
        field.fields.push(copy);
    }
};

/**
 * remove N fields
 * @param {{titleSingular: string, titlePlural: string, codeName: string, quantity: number, step: number, min: number, max: number|undefined}} field
 * @param {number} N
 */
const removeFields = (field: IQuantitySelectorField, N: number) => {
    for(let i=0; i<N; i++){
        field.fields.pop();
    }
};