import {renderHelper} from './autocomplete-helper';
import {dispatchEvent} from '../common';
import { KeyCodes } from '../../enums/keyboard';
import { AutocompleteSettings } from '../../interfaces/autocomplete';

/**
 * JSOcean Autocomplete: Desktop Version
 */
class JSOAutocompleteDesktop{

    /**
     * properties
     */
    settings: AutocompleteSettings;
    closed: boolean;
    onSelect: Function;
    $root: HTMLElement;
    $input: HTMLFormElement;
    $eventsRoot: HTMLElement;
    filtered: any[];
    $dropdown: any;

    /**
     * init
     * @param {object=} settings
     * @param {HTMLElement} $root
     * @param {HTMLElement} $input
     * @param {Function} onSelect
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(settings: AutocompleteSettings, $root: HTMLElement, $input: HTMLFormElement, onSelect: Function, $eventsRoot: HTMLElement) {

        this.settings = settings;
        this.closed = this.settings.closed; // state ope autocomplete - opened or closed
        this.onSelect = onSelect;

        this.$root = $root;
        this.$input = $input;
        this.$eventsRoot = $eventsRoot || this.$root;

        // autocomplete data list
        this.filtered = [];

        // init views
        this.initViews();
    }

    /**
     * init views
     * @return {Promise<void>}
     */
    async initViews(){

        if(this.closed){
            await this.close(false);
        }
        else{
            await this.open(false);
        }

        // handle plugin events
        this.handleEvents();
    }

    // ----------- EVENTS -------------------

    /**
     * handle plugin events
     */
    handleEvents(){

        /**
         * on input event -> open dropdown
         * https://caniuse.com/#feat=input-event IE10+
         */
        this.$input.addEventListener('input', async () => {

            if(this.$input.value.trim() !== '') {
                await this.open();
            }
            else{
                await this.close();
            }

            // update form data
            if(typeof this.onSelect === 'function') {
                this.onSelect(this.$input.value.trim(), this.filtered);
            }
        });

        /**
         * handle text box onclick event
         */
        this.$input.addEventListener('click', evt => {

            if(this.$input.value.trim() !== ''){
                this.open();
            }
        });

        /**
         * prevent closing dropdown when clicking on the widget
         */
        this.$root.addEventListener('click', evt => {
            evt.stopPropagation();
        });

        /**
         * on ENTER -> select appropriate item (if needed)
         * on ESC -> clear text box and close dropdown
         * on UP / DOWN -> navigation up and down items list
         */
        this.$input.addEventListener('keydown', async evt => {

            if (evt.which === KeyCodes.ESC || evt.keyCode === KeyCodes.ESC) {
                evt.preventDefault();
                await this.close();

                this.updateTextBoxData('');
            }

            if (evt.which === KeyCodes.UP || evt.keyCode === KeyCodes.UP) {
                evt.preventDefault();
                this.markNextItem('up');
            }

            if (evt.which === KeyCodes.DOWN || evt.keyCode === KeyCodes.DOWN) {
                evt.preventDefault();
                this.markNextItem('down');
            }

            if (evt.which === KeyCodes.ENTER || evt.keyCode === KeyCodes.ENTER) {
                evt.preventDefault();
                this.handleEnter();
            }
        });

        /**
         * on click outside -> close
         */
        document.addEventListener('click', () => {
            this.close();
        });
    }

    /**
     * stop events propagation
     * @param {object} evt
     */
    stopPropagation(evt: Event){
        evt.stopPropagation();
    }

    // ----------- BULLETS ---------

    /**
     * while using up / down arrows, select next item
     * @param {string} direction - 'up' / 'down'
     */
    markNextItem(direction: string){

        if(!this.$dropdown) return;

        // find all items
        const $items = Array.from(this.$dropdown.querySelectorAll(`.${this.settings.itemClass}`) as NodeListOf<HTMLElement>);

        if(!$items || $items.length <= 0) return;

        // find selected item
        const selectedItemIndex = $items.findIndex(($item: HTMLElement) => $item.classList.contains(`${this.settings.selectedItemClass}`));

        const itemsNumber = $items.length;

        if(selectedItemIndex === -1){

            if(direction === 'down'){
                // select first item
                $items[0].classList.add(this.settings.selectedItemClass);
            }
            else{
                // select last item
                $items[itemsNumber - 1].classList.add(this.settings.selectedItemClass);
            }
        }
        else{
            const nextItemIndex = this.getNextItemIndex(direction, selectedItemIndex, itemsNumber);

            // remove 'selected' class
            $items[selectedItemIndex].classList.remove(this.settings.selectedItemClass);

            // find next
            $items[nextItemIndex].classList.add(this.settings.selectedItemClass);
        }
    }

    /**
     * user pressed enter
     */
    handleEnter(){
        if(!this.$dropdown){

            // if the drop-down is closed ->
            // -- if the text box is empty -> trigger validation
            // -- if the text box is not empty -> open the drop-down

            if(this.$input.value.trim() !== ''){
                this.open();
            }
            else{
                // dispatch event that autocomplete data is loaded via AJAX request
                dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}invalid`, '');
            }

            return;
        }

        // if there is an open drop-down ->
        // -- if there is no selected item -> close the dropdown
        // -- if there is no selected item, but there is only one option in the list -> select this option
        // -- if there is a selected item -> select it and close the dropdown
        const $items = this.$dropdown.querySelectorAll(`.${this.settings.itemClass}`);
        if($items.length === 1){
            this.updateTextBoxData($items[0].textContent.trim());
        }
        else{
            const $selectedItem = this.$dropdown.querySelector(`.${this.settings.selectedItemClass}`);
            if($selectedItem){
                this.updateTextBoxData($selectedItem.textContent.trim());
            }
        }

        // close text box
        this.close();
    }

    /**
     * get next item index
     * @param {string} direction - 'up' / 'down'
     * @param {number} selectedItemIndex - current item index
     * @param {number} itemsNumber
     * @return {number} - next item index or -1 if index is undefined
     */
    getNextItemIndex(direction: string, selectedItemIndex: number, itemsNumber: number){

        if(itemsNumber === 0) return -1;

        if(direction === 'down'){
            if(selectedItemIndex === itemsNumber - 1){ // last item
                return 0; // start from the beginning
            }
            else{
                return selectedItemIndex + 1; // next item
            }
        }
        else{
            // up
            if(selectedItemIndex === 0){ // first item
                return itemsNumber - 1; // go to the end
            }
            else{
                return selectedItemIndex - 1; // previous items
            }
        }
    }

    // ----------- RENDER ---------------------

    /**
     * render autocomplete dropdown
     * @return {HTMLElement}
     */
    async renderDropdown(){

        if(!this.$dropdown){
            this.$dropdown = document.createElement('div');
            this.$dropdown.className = `jso-dropdown ${this.settings.dropdownClassName} ${this.settings.animation ? 'jso-' + this.settings.animation : ''}`;
            this.$dropdown.innerHTML = await this.render();
            this.$input.parentNode.insertBefore(this.$dropdown, this.$input.nextElementSibling);

            /**
             * dropdown onclick event -> prevent from closing
             */
            this.$dropdown.addEventListener('click', this.stopPropagation);

            /**
             * when TAB arrive to an item, once pressed ENTER -> select this item
             */
            this.$dropdown.addEventListener('keydown', (evt: KeyboardEvent) => {

                const $target = evt.target as HTMLElement;

                if((evt.which === KeyCodes.ENTER || evt.keyCode === KeyCodes.ENTER) && $target && $target.classList.contains(this.settings.itemClass)){

                    this.updateTextBoxData($target.textContent.trim());

                    // close text box
                    this.close();
                }

                this.stopPropagation(evt);
            });
        }
        else{
            this.$dropdown.innerHTML = await this.render();
        }

        /**
         * item onclick event
         */
        const $items = this.$dropdown.querySelectorAll(`.${this.settings.itemClass}`);
        for(let i=0; i<$items.length; i++){
            $items[i].addEventListener('click', (evt: Event) => {

                const $target = evt.currentTarget as HTMLElement;

                this.updateTextBoxData($target.textContent.trim());

                // close text box
                this.close();
            });
        }

        return this.$dropdown;
    }

    /**
     * render HTML
     * @return {string}
     */
    async render(){
        const inputValue = this.$input.value;
        this.filtered = (await this.settings.onChange(this.$input.value)) || [];
        return renderHelper(this.settings, this.filtered, inputValue);
    }

    // ----------- OPEN / CLOSE ---------------

    /**
     * open desktop autocomplete
     * @param {boolean=} sendEvent - if event should be sent
     */
    async open(sendEvent: boolean = true){

        const isClosed = this.$dropdown === undefined;

        // render dropdown
        this.$dropdown = await this.renderDropdown();

        this.closed = false;

        this.$root.classList.remove(this.settings.closedClass);
        this.$root.classList.add(this.settings.openedClass);

        // dispatch event when autocomplete is opened
        if(isClosed && sendEvent) {
            dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}opened`, {type: 'desktop'});
        }
    }

    /**
     * close helper: if animation enabled -> add class 'end' and wait till the animation has finished
     * @return {Promise}
     */
    closeHelper(){

        return new Promise(resolve => {

            if(this.$dropdown && this.settings.animation){

                this.$dropdown.classList.add('jso-animation-end');

                window.setTimeout(() => {
                    resolve(null);
                }, this.settings.duration);
            }
            else{
                resolve(null);
            }
        });
    }

    /**
     * close both desktop and mobile autocompletes
     * @param {boolean=} sendEvent - if event should be sent
     */
    async close(sendEvent = true){

        const isOpened = this.$dropdown !== undefined;

        this.closed = true;

        this.$root.classList.remove(this.settings.openedClass);
        this.$root.classList.add(this.settings.closedClass);

        await this.closeHelper();

        if(this.$dropdown) {
            this.$dropdown.removeEventListener('click', this.stopPropagation);
            this.$dropdown.parentNode.removeChild(this.$dropdown);
            this.$dropdown = undefined;
        }

        // dispatch event when autocomplete is closed
        if(isOpened && sendEvent) {
            dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}closed`, {type: 'desktop'});
        }
    }

    // ---------- API --------------------------

    /**
     * update text box value and send data back to the form
     * @param {string} value
     */
    updateTextBoxData(value: string){

        // put item value in text box
        this.$input.value = value;

        if(typeof this.onSelect === 'function') {
            this.onSelect(value, this.filtered);
        }
    }

}

export default JSOAutocompleteDesktop;