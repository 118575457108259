import JSOQuantitySelectorDesktop from './quantity-selector-desktop';
import JSOQuantitySelectorMobile from './quantity-selector-mobile';
import { log, getViewportSize, dispatchEvent } from '../common';
import { getFromStorage, appendToStorage } from '../../dal/storage';
import { KeyCodes } from '../../enums/keyboard';
import { IQuantitySelectorField, QuantitySelectorSettings } from '../../interfaces/quantity-selector';

/*
    JSOcean Quantity Selector
    Usage:
    HTML Structure:

    <div class="jso-quantity-selector-fields" id="root"></div>

    JavaScript call:
    import JSOQuantitySelector from '../../jso-quantity-selector/js/jso-quantity-selector';
    ...
    new JSOQuantitySelector({

    }, document.getElementById('root'), document.getElementById('root'));
 */

/**
 * quantity selector defaults
 * @type {Object}
 */
const defaults: QuantitySelectorSettings = {

    // general settings
    fields: [
        {
            titleSingular: 'Adult',
            titlePlural: 'Adults',
            valueTextSingular: '',
            valueTextPlural: '',
            codeName: 'adults',
            quantity: 2,
            step: 1,
            min: 1,
            max: 30, // undefined = unlimited number of adults
            type: 'buttons' // buttons, select
        },
        {
            titleSingular: 'Children',
            titlePlural: 'Children',
            valueTextSingular: '',
            valueTextPlural: '',
            codeName: 'children',
            quantity: 0,
            step: 1,
            min: 0,
            max: 10, // undefined = unlimited number of children
            type: 'buttons', // buttons, select

            dependantField: {
                titleSingular: 'How old is the child?',
                titlePlural: 'How old are the children?',
                valueTextSingular: ' year old',
                valueTextPlural: ' years old',
                codeName: 'age',
                quantity: 12,
                step: 1,
                min: 0,
                max: 17,
                type: 'select' // buttons, select
            }
        },
        {
            titleSingular: 'Room',
            titlePlural: 'Rooms',
            valueTextSingular: '',
            valueTextPlural: '',
            codeName: 'rooms',
            quantity: 1,
            step: 1,
            min: 1,
            max: 30, // undefined = unlimited number of rooms
            type: 'buttons', // buttons, select
        }
    ],
    closed: true, // true if it is closed by default

    // animation settings
    animation: '', // possible values: '', 'fade', 'slide'
    duration: 300, // in milliseconds

    // storage settings
    storageType: '', // '', 'local-storage', 'session-storage', 'cookies'
    storageName: 'jso-date-range-picker', // this setting is used like key name in web storage, or like cookie name
    cookiesExpiration: -1, // cookies expiration in minutes (-1 = cookies expire when browser is closed)

    // general classes
    openedClass: 'jso-quantity-selector-opened',
    closedClass: 'jso-quantity-selector-closed',

    // desktop version settings and classes
    dropdownClassName: 'jso-quantity-selector-dropdown',

    // mobile version settings and classes
    doneButtonText: 'Done',
    mobileHeaderText: 'Quantity Selector',
    mobileBreakpoint: 768, // if viewport width <= mobileBreakpoint -> opened mobile popup instead of desktop dropdown
    popupClassName: 'jso-quantity-selector-popup',
    popupCloseButtonClass: 'jso-quantity-selector-close',

    debug: false,
};

/**
 * JSOcean Quantity Selector
 */
class JSOQuantitySelector{

    /**
     * properties
     */
    settings: QuantitySelectorSettings;
    fields: IQuantitySelectorField[];
    $root: HTMLElement;
    closed: boolean;
    $eventsRoot: HTMLElement;
    mobile: JSOQuantitySelectorMobile;
    desktop: JSOQuantitySelectorDesktop;
    $tooltip: HTMLElement;

    /**
     * init
     * @param {object=} userSettings
     * @param {HTMLElement} $root
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(userSettings: QuantitySelectorSettings, $root: HTMLElement, $eventsRoot: HTMLElement) {

        this.settings = {...defaults, ...userSettings};

        // deep copy of fields array
        this.fields = JSON.parse(JSON.stringify(this.settings.fields));

        // if storage is enabled, restore country name from the storage
        this.restoreFromStorage();

        this.$root = $root;
        this.closed = this.settings.closed; // opened or closed by default

        if(!this.$root){
            log(this.settings, 'Quantity selector root element is not provided.');
            // it's not possible to continue
            return;
        }

        // init events root
        this.$eventsRoot = $eventsRoot || this.$root;

        // render fields
        this.render();

        if(this.closed){
            this.close();
        }
        else{
            this.open();
        }

        // handle quantity selector events
        this.handleEvents();
    }

    /**
     * check if data received from storage is valid
     * @param {object} data
     * @return {boolean} true = data is valid
     */
    isStorageDataValid(data: any){

        if(!data || !data.quantitySelector || !data.quantitySelector.fields) return false;

        return Array.isArray(data.quantitySelector.fields);
    }

    /**
     * if storage is enabled, restore country name from the storage
     */
    restoreFromStorage(){

        if(this.settings.storageType) {
            /*
               The result should be:
               {
                   quantitySelector: {
                       fields: [...]
                   },
                   ... other widgets ....
               }
           */
            let data: any = getFromStorage(this.settings.storageType, this.settings.storageName);

            try{
                data = JSON.parse(data);
            }
            catch(err){}

            if(!this.isStorageDataValid(data)) return;

            this.fields = data.quantitySelector.fields;
        }
    }

    // ---------- RENDER ---------------

    /**
     * render quantity selector HTML according to the provided fields
     */
    render(){

        const $btn = this.$root.querySelector('.jso-quantity-selector-fields');

        if(!$btn) return;

        $btn.innerHTML = this.fields.map(field => {
            return `
                <span class="jso-quantity-selector-text">${field.quantity} ${field.quantity === 1 ? field.titleSingular : field.titlePlural}</span>
            `;
        }).join('');
    }

    // ---------- EVENTS ---------------

    /**
     * stop events propagation
     * @param {object} evt
     */
    stopPropagation(evt: Event){
        evt.stopPropagation();
    }

    /**
     * handle quantity selector events
     */
    handleEvents(){

        /**
         * on root click -> open dropdown for desktop or popup for mobile
         */
        this.$root.addEventListener('click', evt => {

            evt.stopPropagation();

            if(!this.closed){

                // close desktop dropdown or mobile popup
                this.close();
            }
            else{
                // open desktop dropdown or mobile popup
                this.open();
            }
        });

        /**
         * when the root is focused and user presses ENTER -> toggle the drop-down or mobile popup
         */
        this.$root.addEventListener('keydown', evt => {

            if (evt.which === KeyCodes.ENTER || evt.keyCode === KeyCodes.ENTER) {

                evt.stopPropagation();

                if(!this.closed){

                    // close desktop dropdown or mobile popup
                    this.close();
                }
                else{
                    // open desktop dropdown or mobile popup
                    this.open();
                }
            }
        });

        /**
         * click outside -> close
         */
        document.addEventListener('click', () => {
            this.close();
        });
    }

    /**
     * once fields are updated
     */
    onFieldsUpdate(newFields: IQuantitySelectorField[]){

        // deep copy of fields array
        this.fields = JSON.parse(JSON.stringify(newFields));

        // close mobile popup if needed
        if(this.mobile) {
            this.close();
        }

        // render fields
        this.render();

        // if storage is enabled, save selected country name to the storage
        if(this.settings.storageType){

            appendToStorage(this.settings.storageType, this.settings.storageName, 'quantitySelector', {
                fields: this.fields
            }, this.settings.cookiesExpiration);
        }

        // dispatch event when value changes
        dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-onchange', {
            data: newFields,
            value: this.getValue()
        });
    }

    // -------------- OPEN / CLOSE ---------------

    /**
     * open desktop dropdown or mobile popup
     */
    open(){

        let isClosed = false;
        let eventType = 'desktop';

        if(getViewportSize().width > this.settings.mobileBreakpoint){

            isClosed = !this.desktop || this.desktop && this.desktop.$root === undefined;

            // init desktop version
            this.desktop = new JSOQuantitySelectorDesktop(this.settings, this.fields, this.onFieldsUpdate.bind(this), this.$eventsRoot);

            // append desktop version dropdown to the root
            this.$root.appendChild(this.desktop.$root);
        }
        else{
            isClosed = !this.mobile || this.mobile && this.mobile.$root === undefined;
            eventType = 'mobile';

            // init mobile version
            this.mobile = new JSOQuantitySelectorMobile(this.settings, this.fields, this.onFieldsUpdate.bind(this), this.$eventsRoot);

            // append mobile version popup to the root
            this.$root.appendChild(this.mobile.$root);
        }

        this.closed = false;

        this.$root.classList.remove(this.settings.closedClass);
        this.$root.classList.add(this.settings.openedClass);

        if(isClosed){

            // dispatch event when drop-down / popup opens
            dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-opened', { type: eventType });
        }
    }

    /**
     * close helper: if animation enabled -> add class 'end' and wait till the animation has finished
     * @param {Function} callback
     */
    closeHelper(callback: Function){

        if(this.desktop && this.desktop.$root && this.settings.animation){

            this.desktop.$root.classList.add('jso-animation-end');

            window.setTimeout(() => {
                callback();
            }, this.settings.duration);
        }
        else{
            callback();
        }
    }

    /**
     * close desktop dropdown or mobile popup
     */
    close(){
        if(this.desktop) {

            this.closeHelper(() => {

                const isOpened = this.desktop.$root !== undefined;

                if(this.desktop.$root){
                    this.desktop.$root.parentNode.removeChild(this.desktop.$root);
                }

                this.desktop = undefined;

                if(isOpened){

                    // dispatch event when drop-down closes
                    dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-closed', { type: 'desktop' });
                }
            });
        }

        if(this.mobile) {

            const isOpened = this.mobile.$root !== undefined;

            this.mobile.$root.parentNode.removeChild(this.mobile.$root);
            this.mobile = undefined;

            if(isOpened){

                // dispatch event when popup closes
                dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-closed', { type: 'mobile' });
            }
        }

        this.closed = true;

        this.$root.classList.remove(this.settings.openedClass);
        this.$root.classList.add(this.settings.closedClass)
    }

    // --------------- API ------------------------

    /**
     * get value entered by the user
     * @return {Array.<{codeName: string, quantity: number}>}
     */
    getValue(){

        const fields: IQuantitySelectorField[] = [];

        for(let i=0; i<this.fields.length; i++){
            const field = this.fields[i];

            fields.push({
                codeName: field.codeName,
                quantity: field.quantity
            });

            if(field.fields){
                for(let j=0; j<field.fields.length; j++){
                    const nestedField = field.fields[j];

                    fields.push({
                        codeName: `${nestedField.codeName}_${j}`,
                        quantity: nestedField.quantity
                    });
                }
            }
        }

        return fields;
    }

    /**
     * show tooltip - it may be used as validation error, but not limited
     * @param {string} msg
     * @param {string} className
     */
    showTooltip(msg: string, className: string){

        this.$tooltip = document.createElement('div');
        this.$tooltip.className = className;
        this.$tooltip.innerHTML = msg;
        this.$root.appendChild(this.$tooltip);

        // the event is dispatched when a tooltip shows
        dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-tooltip-shown', {message: msg});
    }

    /**
     * hide tooltip - it may be used as validation error, but not limited
     */
    hideTooltip(){

        if(this.$tooltip && this.$tooltip.parentNode){
            this.$tooltip.parentNode.removeChild(this.$tooltip);

            // the event is dispatched when a tooltip hides
            dispatchEvent(this.$eventsRoot, 'jso-quantity-selector-tooltip-hidden', '');
        }
    }
}

export default JSOQuantitySelector;