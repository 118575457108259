import { Settings } from './interfaces/interfaces';
import { log } from './domain/common';
import JSOBookingForm from './booking-form';
import '../css/jso-booking-form.css';
import '../css/jso-autocomplete.css';
import '../css/jso-calendar.css';
import '../css/jso-date-range-picker.css';
import '../css/jso-dropdown.css';
import '../css/jso-popup.css';
import '../css/jso-quantity-selector.css';

/**
 * Allows to TypeScript recognize library namespace
 */
declare global {
  interface Window {
    jso: any;
  }

  interface HTMLElement {
    bookingForm: any;
  }
}

/**
 * Add plugin to the jso namespace
 * @type {*|{}}
 */
 window.jso = window.jso || {};

 /**
  * Ocean Star Library
  * pathToRoot - path to the root element, for example '.jso-booking-form'
  */
 window.jso.bookingForm = (pathToRoot: string, settings: Settings) => {
 
    const $roots = document.querySelectorAll(pathToRoot);
 
    if(!$roots || $roots.length <= 0){
      log(settings, `The root element of hotels booking form is missing in HTML.`);
      // it's not possible to continue
      return;
    }
 
    for(let i=0; i<$roots.length; i++){
      const $root = $roots[i] as HTMLElement;
      $root.bookingForm = new JSOBookingForm(settings, $root);
    }
 
    // in case of 1 root only - return this root, so the user could work with events and APIs;
    // otherwise return all available roots
    if($roots.length === 1){
      return $roots[0];
    }
    else{
      return $roots;
    }
 };
