import {removeCharacters} from './text';

/**
 * text filter
 * @param {Array.<{object}>|Array.<string>} data
 * @param {string} term
 * @param {string} filterMode - contains, equals, startsWith
 * @param {string|null=} property - the results will be filtered by this property; otherwise, the whole data item will be taken
 * @param {number|null} maxResults
 * @param {string=} ignoreRegex - default value is '[~!@#$%^&*()+=`'"/\_]+'
 * @return {Array.<*>} filtered data
 */
export const textFilter = (data: string[], term: string, filterMode: string, property: string, maxResults: number, ignoreRegex: string = '[~!@#$%^&*()+=`\'"\/\\_]+') => {

    // remove unnecessary characters from the search term
    const formattedTerm = removeCharacters(term, ignoreRegex);

    // filter only items that starts with formatted term
    const filteredItems = data.filter((item: any) => {

        const value = property === null ? item : item[property];
        const formattedItem = removeCharacters(value, ignoreRegex);

        switch(filterMode){

            case 'contains': {
                return formattedItem.indexOf(formattedTerm) !== -1;
            }

            case 'equals': {
                return formattedItem === formattedTerm;
            }

            default: {
                // startsWith
                return formattedItem.startsWith(formattedTerm);
            }
        }

    });

    // return only top = 'maxResults' items
    return (maxResults === null) ? filteredItems : filteredItems.slice(0, maxResults);
};