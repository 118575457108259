/**
 * remove characters
 * @param {string} text
 * @param {string} regex - remove characters regex expression
 * @return {string}
 */
export const removeCharacters = (text: string, regex: string) => {

    if(!text || !regex) return '';

    //create regex
    const regexExpr = new RegExp(regex, 'ig');

    //return text.replace(/[^a-zA-Z0-9]+/g,'').toLowerCase();
    return text.trim().replace(regexExpr, '').toLowerCase();
};

/**
 * remove all spaces from the given string
 * @param {string} txt
 * return {string}
 */
export const removeAllSpaces = (txt: string) => {
    return txt.replace(/ /g,'');
};