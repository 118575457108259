/**
 * check if storage type is enabled
 * @param {string} storageType - 'local-storage', 'session-storage', or 'cookies'
 * @return {boolean}
 */
export const isStorageEnabled = (storageType: string) => {

    if(storageType === 'cookies') return true;

    if(storageType === 'local-storage'){

        const temp = 'test';

        try {
            window.localStorage.setItem(temp, temp);
            window.localStorage.removeItem(temp);
            return true;
        }
        catch(e) {
            return false;
        }
    }

    if(storageType === 'session-storage'){

        const temp = 'test';

        try {
            window.sessionStorage.setItem(temp, temp);
            window.sessionStorage.removeItem(temp);
            return true;
        }
        catch(e) {
            return false;
        }
    }

    return false;
};

/**
 * Set Cookie
 * @param {string} name - cookie name
 * @param {string} value - cookie value
 * @param {number=} expiration - cookie expiration in minutes (-1 = cookie expires when browser is closed)
 * @param {boolean=} secure
 * @param {string|undefined=} samesite - strict or lax
 */
export const setCookie = (name: string, value: string, expiration: number = -1, secure = false, samesite: string = undefined) => {

    const escapedValue = encodeURIComponent(JSON.stringify(value));
    const exp = Number(expiration) || -1;
    let cookieString = '';

    if(exp === -1) {

        // cookie expires when browser is closed
        // If neither expires nor max-age specified the cookie will expire at the end of session.
        cookieString = `${name}=${escapedValue};path=/;`;
    }
    else{
        const date = new Date();
        date.setMinutes(date.getMinutes() + expiration);

        // option to set expiration in days:
        //864e5 = 86400000 = 1000*60*60*24 represents the number of milliseconds in a 24 hour day.
        //date.setTime(date.getTime() + (days * 864e5));

        const expires = date.toUTCString();

        cookieString = `${name}=${escapedValue};path=/; expires=${expires}`;
    }

    if(samesite){
        // The strict value will prevent the cookie from being sent by the browser to the target site in all cross-site browsing context, even when following a regular link.
        // The lax value will only send cookies for TOP LEVEL navigation GET requests. This is sufficient for user tracking, but it will prevent many CSRF attacks.
        cookieString += `;samesite=${samesite}`;
    }

    if(secure){
        // Cookie to only be transmitted over secure protocol as https. Before Chrome 52, this flag could appear with cookies from http domains.
        cookieString += ';secure';
    }

    document.cookie = cookieString;
};

/**
 * Get cookie by name
 * @param {string} name - cookie name
 * @return {*}
 */
export const getCookie = (name: string) => {

    const cookies = document.cookie.split(';');

    for (let i=0; i<cookies.length; i++){

        const cookie = cookies[i];
        const index = cookie.indexOf('=');
        const key = cookie.substr(0, index);
        const value = cookie.substr(index + 1);

        if(key.trim().toLowerCase() === name.trim().toLowerCase()){
            return decodeURIComponent(value);
        }
    }

    return null;
};

/**
 * save a value to the local storage
 * @param {string} storageName
 * @param {*} value
 */
export const setLocalStorageItem = (storageName: string, value: any) => {
    const escapedValue = encodeURIComponent(JSON.stringify(value));
    window.localStorage.setItem(storageName, escapedValue);
};

/**
 * get a value to the local storage
 * @param {string} storageName
 * @return {*} value
 */
export const getLocalStorageItem = (storageName: string) => {
    const value = window.localStorage.getItem(storageName);
    return decodeURIComponent(value);
};

/**
 * save a value to the session storage
 * @param {string} storageName
 * @param {*} value
 */
export const setSessionStorageItem = (storageName: string, value: any) => {
    const escapedValue = encodeURIComponent(JSON.stringify(value));
    window.sessionStorage.setItem(storageName, escapedValue);
};

/**
 * get a value to the session storage
 * @param {string} storageName
 * @return {*} value
 */
export const getSessionStorageItem = (storageName: string) => {
    const value = window.sessionStorage.getItem(storageName);
    return decodeURIComponent(value);
};

/**
 * save to web storage or cookies
 * @param {string} storageType - 'local-storage', 'session-storage', or 'cookies'
 * @param {string} storageName - it is used like key name in web storage, or like cookie name
 * @param {*} value - the value that should be stored
 * @param {number=} cookiesExpiration - cookies expiration in minutes (-1 = cookies expire when browser is closed)
 */
export const saveToStorage = (storageType: string, storageName: string, value: any, cookiesExpiration: number = -1) => {

    // if this storage type is not supported -> do nothing
    if(!isStorageEnabled(storageType)) return;

    if(storageType === 'cookies') {
        setCookie(storageName, value, cookiesExpiration);
    }

    if(storageType === 'local-storage') {
        setLocalStorageItem(storageName, value);
    }

    if(storageType === 'session-storage') {
        setSessionStorageItem(storageName, value);
    }
};

/**
 * get value from storage
 * @param {string} storageType - 'local-storage', 'session-storage', or 'cookies'
 * @param {string} storageName
 * @return {*} value
 */
export const getFromStorage = (storageType: string, storageName: string) => {

    // if this storage type is not supported -> do nothing
    if(!isStorageEnabled(storageType)) return null;

    if(storageType === 'cookies') {
        return getCookie(storageName);
    }

    if(storageType === 'local-storage') {
        return getLocalStorageItem(storageName);
    }

    if(storageType === 'session-storage') {
        return getSessionStorageItem(storageName);
    }

    return null;
};

/**
 * if storage already exists, append to it, otherwise create a new one
 * @param {string} storageType - 'local-storage', 'session-storage', or 'cookies'
 * @param {string} storageName - it is used like key name in web storage, or like cookie name
 * @param {string} key - this is a property name that is used to store the value inside the general widgets object
 * @param {*} value - the value that should be stored
 * @param {number=} cookiesExpiration - cookies expiration in minutes (-1 = cookies expire when browser is closed)
 */
export const appendToStorage = (storageType: string, storageName: string, key: string, value: any, cookiesExpiration: number = -1) => {

    let data: any = getFromStorage(storageType, storageName);

    try{
        data = JSON.parse(data);
    }
    catch(err){}

    if(!data) {

        // create new entry
        data = {
            [key]: value
        };
    }
    else{
        // append the entry
        data[key] = value;
    }

    // overwrite the storage
    saveToStorage(storageType, storageName, data, cookiesExpiration = -1);
};