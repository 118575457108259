import JSOAutocompleteDesktop from './autocomplete-desktop';
import JSOAutocompleteMobile from './autocomplete-mobile';
import { log, dispatchEvent } from '../common';
import { appendToStorage, getFromStorage } from '../../dal/storage';
import { AutocompleteSettings } from '../../interfaces/autocomplete';

/*
    JSOcean Autocomplete
    Usage:
    HTML Structure:
    <div id="root">
         <input  type="text"
             name="..."
             placeholder="..."
             aria-label="..."
             autocomplete="off"
             spellcheck="false" />
    </div>

    JavaScript call:
    import JSOAutocomplete from '../jso-autocomplete/js/jso-autocomplete';
    ...
    this.autocomplete = new JSOAutocomplete({
        inputClassName: 'jso-autocomplete-input'
    }, document.getElementById('root'), document.getElementById('root'));
 */

/**
 * autocomplete defaults
 * @type {Object}
 */
export const defaults : AutocompleteSettings = {
    closed: true, // true if autocomplete is closed by default

    // data and filter
    maxResults: 5,
    ignoreRegex: '[~!@#$%^&*()+=`\'"\/\\_]+',
    noResultsText: 'No results found',
    filterMode: 'contains', // 'startsWith', 'contains', 'equals'
    enableHighlighting: true,

    // animation settings
    animation: '', // possible values: '', 'fade', 'slide'
    duration: 300, // in milliseconds

    // storage settings
    storageType: '', // '', 'local-storage', 'session-storage', 'cookies'
    storageName: 'jso-autocomplete', // this setting is used like key name in web storage, or like cookie name
    cookiesExpiration: -1, // cookies expiration in minutes (-1 = cookies expire when browser is closed)
    storageProperty: 'autocomplete', // in case storage contains not only autocomplete, but other objects too, autocomplete settings will be in the nested object {autocomplete: {...}}, where 'autocomplete' is a 'storageProperty'

    // general classes
    openedClass: 'jso-autocomplete-opened',
    closedClass: 'jso-autocomplete-closed',
    itemClass: 'jso-autocomplete-item',
    selectedItemClass: 'jso-autocomplete-selected-item',

    // desktop version classes
    inputClassName: 'jso-autocomplete-input',
    dropdownClassName: 'jso-autocomplete',

    // mobile version settings and classes
    mobileHeaderText: 'Items',
    mobilePlaceholderText: 'Select an item',
    inputMobileClassName: 'jso-autocomplete-input-mobile',
    popupClassName: 'jso-autocomplete-popup',
    popupCloseButtonClass: 'jso-autocomplete-popup-close',
    popupInnerInputID: 'jso-autocomplete-popup-input',

    // advanced settings
    debug: false,
    eventsPrefix: 'autocomplete-', // used to prefix all events triggered by autocomplete, for example 'autocomplete-loaded' etc.

    // this function will execute each time user changes autocomplete value
    onChange: (userInput: string) => {},

    // this function is used to render an autocomplete item received from the data source
    renderItem: (dataItem: any) => '',

    // this function is used to filter autocomplete data items by user input in the case when the user didn't use the drop-down
    // but just insert the value manually and press ENTER
    filter: (data: any, userInput: string) => {},

    // this function maps autocomplete item properties to the data that will be passed as GET / POST request after the form submission
    getSubmitProperties: (dataItem: any) => {}
};

/**
 * JSOcean Autocomplete
 */
class JSOAutocomplete{

    /**
     * properties
     */
    settings: AutocompleteSettings;
    closed: boolean;
    selectedItem: any;
    $root: HTMLElement;
    $eventsRoot: HTMLElement;
    $input: HTMLFormElement;
    $mobileInput: HTMLFormElement;
    desktop: JSOAutocompleteDesktop;
    mobile: JSOAutocompleteMobile;
    $tooltip: HTMLElement;

    /**
     * init
     * @param {AutocompleteSettings=} userSettings
     * @param {HTMLElement} $root
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(userSettings: AutocompleteSettings, $root: HTMLElement, $eventsRoot: HTMLElement) {

        this.settings = {...defaults, ...userSettings};
        this.closed = this.settings.closed; // opened or closed by default
        this.selectedItem = null; // the item selected in autocomplete

        this.$root = $root;

        if(!this.$root){
            log(this.settings, 'Autocomplete root element is not provided.');
            // it's not possible to continue
            return;
        }

        // set root styles
        this.$root.style.position = 'relative';

        // init events root
        this.$eventsRoot = $eventsRoot || this.$root;

        this.$input = this.$root.querySelector(`.${this.settings.inputClassName}`);

        if(!this.$input){
            log(this.settings, `An element with class "${this.settings.inputClassName}" is missing in HTML.`);
            // it's not possible to continue
            return;
        }

        // if storage is enabled, restore autocomplete item value from the storage
        this.restoreFromStorage();

        // clone input to create its mobile version
        this.$mobileInput = this.$input.cloneNode(true) as HTMLFormElement;
        this.$mobileInput.classList.add(this.settings.inputMobileClassName);
        this.$input.parentNode.insertBefore(this.$mobileInput, this.$input.nextElementSibling);

        // load autocomplete data from 'data/data.json' and init desktop and mobile views
        this.initViews();
    }

    /**
     * check if data received from storage is valid
     * @param {object} data
     * @return {boolean} true = data is valid
     */
    isStorageDataValid(data: any){

        if(!data || !data[this.settings.storageProperty]) return false;

        const nestedObject = data[this.settings.storageProperty];
        return typeof nestedObject.value === 'string';
    }

    /**
     * if storage is enabled, restore autocomplete value from the storage
     */
    restoreFromStorage(){

        if(this.settings.storageType) {

            /*
                The result should be:
                {
                    autocomplete: {
                        value: "..."
                    },
                    ... other widgets ....
                }
            */
            let data: any = getFromStorage(this.settings.storageType, this.settings.storageName);

            try{
                data = JSON.parse(data);
            }
            catch(err){}

            if(!this.isStorageDataValid(data)) return;

            const nestedObject = data[this.settings.storageProperty];
            this.$input.value = nestedObject.value || '';
        }
    }

    /**
     * load autocomplete data from 'data/data.json' and init desktop and mobile views
     */
    initViews(){

        // this is needed so in case input field contains a value on page load
        // (inserted by the user or restored from storage), the form could pass validation and be submitted
        this.onSelect(this.$input.value, []);

        // init desktop version
        this.desktop = new JSOAutocompleteDesktop(this.settings, this.$root, this.$input, this.onSelect.bind(this), this.$eventsRoot);

        // init mobile version
        this.mobile = new JSOAutocompleteMobile(this.settings, this.$root, this.$mobileInput, this.onSelect.bind(this), this.$eventsRoot);
    }

    // -------------- Events ----------------------

    /**
     * once user selects an autocomplete item
     * @param {string} selectedValue
     * @param {Array.<object>=} filtered autocomplete data items - used only in custom AJAX implementation
     */
    onSelect(selectedValue: string, filtered: any){

        this.selectedItem = this.settings.filter(filtered, selectedValue);

        // if storage is enabled, save selected autocomplete item value name to the storage
        if(this.settings.storageType){

            appendToStorage(this.settings.storageType, this.settings.storageName, this.settings.storageProperty, {
                value: selectedValue
            }, this.settings.cookiesExpiration);
        }

        // dispatch event that user selected an autocomplete item
        dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}onchange`, {
            value: selectedValue,
            selected: this.selectedItem
        });
    }

    // -------------- APIs ------------------------

    /**
     * API: close dropdown or popup - used from outside
     */
    close(){
        if(this.desktop){
            this.desktop.close();
        }

        if(this.mobile){
            this.mobile.close();
        }
    }

    /**
     * show tooltip - it may be used as validation error, but not limited
     * @param {string} msg
     * @param {string} className
     */
    showTooltip(msg: string, className: string){

        this.$tooltip = document.createElement('div');
        this.$tooltip.className = className;
        this.$tooltip.innerHTML = msg;
        this.$root.appendChild(this.$tooltip);

        // dispatch event when tooltip is shown
        dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}tooltip-shown`, { message: msg });
    }

    /**
     * hide tooltip - it may be used as validation error, but not limited
     */
    hideTooltip(){

        if(this.$tooltip && this.$tooltip.parentNode){
            this.$tooltip.parentNode.removeChild(this.$tooltip);

            // dispatch event when tooltip is hidden
            dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}tooltip-hidden`, '');
        }
    }

    /**
     * API: get data that can be used outside
     * @return {Array.<object>|null}
     */
    getValue(){
        return this.settings.getSubmitProperties(this.selectedItem, this.$input.value);
    }
}

export default JSOAutocomplete;