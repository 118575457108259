/**
 * serialize date to the format 2020-5-1 (yyyy-MM-dd)
 * @param {Date} date
 * @return {string|null}
 */
export const serializeDate = (date: Date) => {

    if(!date || typeof date.getMonth !== 'function') return null;

    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

/**
 * deserialize date from the format 2020-5-1 (yyyy-MM-dd)
 * @param {string} dateString
 * @return {Date|null}
 */
export const deserializeDate = (dateString: string) => {

    if(!dateString || typeof dateString !== 'string') return null;

    dateString = dateString.trim();
    const parts = dateString.split('-');

    if(parts.length !== 3) return null;

    const year = Number(parts[0]);
    const month = Number(parts[1]);
    const day = Number(parts[2]);

    if(isNaN(year) || isNaN(month) || isNaN(day)) return null;

    return new Date(year, month, day);
};