
/**
 * return true if url is absolute, false if relative
 * @param {string} url
 * @return {boolean}
 */
export const isAbsoluteURL = (url: string) => {
    const regex = /^https?:\/\//i;
    return regex.test(url);
};

/**
 * add query string to url
 * EXPECTS KEYS AND VALUES ALREADY BE ENCODED!!!
 * addQueryString('http://www.example.com?foo=bar#hashme', '?bla=valuebla')  OR
 * addQueryString('http://www.example.com?foo=bar#hashme', 'bla=valuebla')
 * @param {string} url
 * @param {string} qs
 * @return {string} - updated url
 */
export const addQueryString = (url: string, qs: string) => {

    // find hash if exists
    const hashIndex = url.indexOf('#');
    const hash = (hashIndex === - 1) ? '' : url.substring(hashIndex);

    // remove hash from url if needed
    if(hashIndex !== -1){
        url = url.substring(0, hashIndex);
    }

    // find query string
    const queryIndex = url.indexOf('?');
    let query = (queryIndex === -1) ? '' : url.substring(queryIndex);

    // remove query string from url if needed
    if(queryIndex !== -1){
        url = url.substring(0, queryIndex);
    }

    // append / replace with new query string
    qs = qs.replace('?', '');

    if(query){
        query += '&' + qs;
    }
    else{
        query += '?' + qs;
    }

    return `${url}${query}${hash}`;
};