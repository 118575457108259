import { ILog } from '../interfaces/interfaces';

/**
 * log message in console
 */
export const log = (settings: ILog, message: string) =>{
    if(settings.debug && console && console.log){
        console.log(`JSOcean: ${message}`);
    }
};

/**
 * get viewport size
 */
export const getViewportSize = () => {
    const el = document.createElement('div');
    el.style.position = 'fixed';
    el.style.top = '0px';
    el.style.left = '0px';
    el.style.right = '0px';
    el.style.bottom = '0px';

    document.documentElement.insertBefore(el, document.documentElement.firstChild);

    const width = el.offsetWidth;
    const height = el.offsetHeight;

    document.documentElement.removeChild(el);

    return {width, height};
};

/**
 * return true if param is object
 */
export const isObject = (obj: any) => {
    return obj && typeof obj === 'object' && !Array.isArray(obj) && Object.prototype.toString.call(obj) !== '[object Date]';
};

/**
 * Performs a deep merge of objects and returns new object.
 * Does not modify objects (immutable).
 * @param {...object} objects - objects to merge
 * @returns {object} New object with merged key / values
 */
export const deepMerge = (...objects: any[]) => {

    return objects.reduce((prev, obj) => {

        // loop through object properties
        for (let [key, value] of Object.entries(obj)) {

            const prevValue = prev[key];

            // if properties are objects
            if(isObject(prevValue) && isObject(value)){

                // merge them recursively
                prev[key] = deepMerge(prevValue, value);
            }
            else{
                prev[key] = value;
            }
        }

        return prev;

    }, {});
};

/**
 * dispatch countries autocomplete custom event
 */
export const dispatchEvent = (element: HTMLElement, eventName: string, details: any) => {

    if(!window.CustomEvent) return;

    const event = new CustomEvent(eventName, {
        detail: details
    });

    element.dispatchEvent(event);
};

/**
 * if parent object is not defined, generate nested objects structure from string like 'obj.source.name' -> {obj: {source: { name: {}}}}
 *
 * if parent object is defined, find the appropriate path inside it;
 * for example, parent = {a: {b: {c: { k: 1}}}}, str = 'a.b'
 * should return {c: { k: 1}}
 */
export const objectFromString = (str: string, parentObject: any = null) => {

    if(typeof str !== 'string' || !str) return null;

    const res = parentObject || {};
    const parts = str.split('.');
    const len = parts.length;

    let temp = res;
    for(let i=0; i<len; i++){
        if(parentObject && (typeof temp !== 'object' || !temp[parts[i]])) return null;
        temp[parts[i]] = parentObject ? temp[parts[i]] : {};
        temp = temp[parts[i]];
    }

    return parentObject ? temp : res;
};