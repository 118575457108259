import { AutocompleteSettings } from '../../interfaces/autocomplete';

/**
 * JSOcean Autocomplete: Helpers
 */

/**
 * helper: render HTML
 * @param {object} settings
 * @param {Array.<object>} filtered autocomplete items
 * @param {string} inputValue
 * @return {string}
 */
export const renderHelper = (settings: AutocompleteSettings, filtered: Array<object>, inputValue: string) => {

    let itemsHTML = filtered.map((item, index) => {
        return settings.renderItem(item, inputValue, index);
    }).join('');

    const noResultsHTML = `<div class="jso-autocomplete-no-results">${settings.noResultsText}</div>`;

    return `<div class="jso-autocomplete-content">${filtered.length > 0 ? itemsHTML : noResultsHTML}</div>`;
};