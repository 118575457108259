import { log } from '../domain/common';
import { Settings } from '../interfaces/interfaces';

/**
 * perform get request to receive JSON
 * https://caniuse.com/#feat=fetch Edge+, IE is not supported without polyfill
 * @param {string} url
 * @param {boolean} debug
 * @return {*}
 */
export const getJSON = async (url: string, debug: boolean) => {

    try {
        const response = await window.fetch(url, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return await response.json();
    }
    catch(ex){
        log({debug} as Settings, ex);
    }

    return [];
};