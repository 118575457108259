import { renderHelper } from './autocomplete-helper';
import { dispatchEvent } from '../common';
import { AutocompleteSettings } from '../../interfaces/autocomplete';

/**
 * JSOcean Autocomplete: Mobile Version
 */
class JSOAutocompleteMobile{

    /**
     * properties
     */
    settings: AutocompleteSettings;
    closed: boolean;
    onSelect: Function;
    $root: HTMLElement;
    $input: HTMLFormElement;
    $eventsRoot: HTMLElement;
    filtered: any[];
    $popup: HTMLElement;
    $dropdown: HTMLElement;
    $itemsBox: HTMLElement;

    /**
     * init
     * @param {object=} settings
     * @param {HTMLElement} $root
     * @param {HTMLElement} $input
     * @param {Function} onSelect
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(settings: AutocompleteSettings, $root: HTMLElement, $input: HTMLFormElement, onSelect: Function, $eventsRoot: HTMLElement) {

        this.settings = settings;
        this.closed = this.settings.closed; // state ope autocomplete - opened or closed
        this.onSelect = onSelect;

        this.$root = $root;
        this.$input = $input;
        this.$eventsRoot = $eventsRoot || this.$root;

        // autocomplete data list
        this.filtered = [];

        // init views
        this.initViews();
    }

    /**
     * init views
     * @return {Promise<void>}
     */
    async initViews(){

        if(this.closed){
            this.close();
        }
        else{
            await this.open();
        }

        // handle mobile events
        this.handleMobileEvents();
    }

    // ----------- EVENTS -------------------

    /**
     * stop events propagation
     * @param {object} evt
     */
    stopPropagation(evt: Event){
        evt.stopPropagation();
    }

    /**
     * handle mobile events
     */
    handleMobileEvents(){

        // on text box click -> open mobile popup
        this.$input.addEventListener('click', evt => {
            evt.stopPropagation();
            this.open();
        });
    }

    /**
     * handle mobile popup events
     */
    handlePopupEvents(){

        // prevent popup closing on document click
        this.$popup.addEventListener('click', this.stopPropagation);

        // handle close button onclick
        const $closeButton = this.$popup.querySelector(`.${this.settings.popupCloseButtonClass}`);

        if($closeButton){
            $closeButton.addEventListener('click', (evt: Event) => {
                evt.stopPropagation();
                this.close();
            });
        }

        // handle inner text box input event
        const $innerTextBox = this.$popup.querySelector(`#${this.settings.popupInnerInputID}`);

        if($innerTextBox){
            $innerTextBox.addEventListener('input', (evt: InputEvent) => {
                const $target = evt.target as HTMLFormElement;
                this.printList($target.value.trim());
            });
        }
    }

    // ----------- RENDER ---------------------

    /**
     * render mobile popup
     * @return {HTMLElement}
     */
    renderMobilePopup(){

        const $popup = document.createElement('div');
        $popup.className = `jso-popup jso-popup-fullscreen ${this.settings.popupClassName}`;
        $popup.innerHTML = this.renderMobileHTML();

        document.body.appendChild($popup);
        this.$dropdown = $popup;

        return $popup;
    }

    /**
     * render mobile HTML
     */
    renderMobileHTML(){

        const currentItemValue = this.$input.value.trim();

        return `
            <div class="jso-popup-box">
                <div class="jso-popup-content-top">
                    <h4>${this.settings.mobileHeaderText || '&nbsp;'}</h4>
                    <button type="button" class="jso-popup-close-button ${this.settings.popupCloseButtonClass}">&nbsp;</button>
                </div>
                <div class="jso-autocomplete-popup-input-box">
                    <input 
                        id="${this.settings.popupInnerInputID}" 
                        type="text" 
                        placeholder="${this.settings.mobilePlaceholderText}" 
                        class="form-control" 
                        value="${currentItemValue ? currentItemValue : '' }"
                    />
                </div>
            </div>`;
    }

    /**
     * print autocomplete list
     * @param {string} inputValue
     */
    async printList(inputValue: string){

        if(this.$itemsBox && this.$itemsBox.parentNode){
            this.$itemsBox.parentNode.removeChild(this.$itemsBox);
        }

        this.$itemsBox = document.createElement('div');
        this.$itemsBox.className = 'jso-autocomplete-mobile-content-box';

        this.filtered = (await this.settings.onChange(inputValue)) || [];

        this.$itemsBox.innerHTML = renderHelper(this.settings, this.filtered, inputValue);

        this.$popup.querySelector('.jso-popup-box').appendChild(this.$itemsBox);

        // handle item onclick event
        const $items = this.$itemsBox.querySelectorAll(`.${this.settings.itemClass}`);

        if($items && $items.length > 0) {
            for (let i = 0; i < $items.length; i++) {

                const $item = $items[i];
                $item.addEventListener('click', () => {

                    const value = $item.textContent.trim();

                    this.$input.value = value;

                    if(typeof this.onSelect === 'function') {
                        this.onSelect(value, this.filtered);
                    }

                    this.close();
                });
            }
        }
    }

    // ----------- OPEN / CLOSE ---------------

    /**
     * open mobile popup
     * @param {boolean=} sendEvent - if event should be sent
     */
    async open(sendEvent: boolean = true){

        const isClosed = this.$popup === undefined;

        // render dropdown
        this.$popup = this.renderMobilePopup();

        // if main text box already contains some value -> handle it accordingly
        const currentItemValue = this.$input.value.trim();

        if(currentItemValue){
            await this.printList(currentItemValue);
        }

        this.handlePopupEvents();

        this.closed = false;

        this.$root.classList.remove(this.settings.closedClass);
        this.$root.classList.add(this.settings.openedClass);

        // set focus to the inner text box
        const $innerTextBox = this.$popup.querySelector(`#${this.settings.popupInnerInputID}`) as HTMLFormElement;

        if($innerTextBox){
            $innerTextBox.focus();
        }

        if(isClosed && sendEvent) {

            // dispatch event when autocomplete popup is opened
            dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}opened`, {type: 'mobile'});
        }
    }

    /**
     * close mobile popup
     * @param {boolean=} sendEvent - if event should be sent
     */
    close(sendEvent = true){

        const isOpened = this.$popup !== undefined;

        if(this.$popup) {
            this.$popup.removeEventListener('click', this.stopPropagation);
            this.$popup.parentNode.removeChild(this.$popup);
            this.$popup = undefined;
        }

        this.closed = true;

        this.$root.classList.remove(this.settings.openedClass);
        this.$root.classList.add(this.settings.closedClass);

        if(isOpened && sendEvent) {
            // dispatch event when autocomplete is closed
            dispatchEvent(this.$eventsRoot, `${this.settings.eventsPrefix}closed`, {type: 'mobile'});
        }
    }
}

export default JSOAutocompleteMobile;