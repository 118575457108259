/**
 * add 0 to the left of the number (if needed)
 * @param {number} number
 * @return {string}
 */
export const twoDigits = (number: number) => {

    let result = number.toString();

    if(result.length < 2){
        result = '0' + result;
    }

    return result;
};

/**
 * format duration
 * @param {number} duration
 * @return {string}
 *
 * for example, duration = 32.75755 -> 1:33
 */
export const formatDuration = (duration: number) => {

    duration = Number(duration) || 0;

    const hour = Math.round(duration / 3600);
    const min = Math.round((duration % 3600) / 60);
    const sec = Math.round(duration % 3600 % 60);

    const hourStr = (hour > 0) ? `${twoDigits(hour)}:` : '';
    return `${hourStr}${twoDigits(min)}:${twoDigits(sec)}`;
};